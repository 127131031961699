<template>
  <ConfirmDialog
    :value.sync="show"
    positiveButtonText="Zapisz"
    negativeButtonText="Anuluj"
    :positiveAction="() => $emit('save')"
    :negativeAction="() => $emit('cancel')"
    title="Edytuj"
    persistent
  >
    <v-layout column slot="content">
      <LabelTextArea
        :value.sync="internalText"
        placeholder="Wpisz tekst.."
        hide-details
      ></LabelTextArea>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
export default {
  props: {
    value: {},
    text: {},
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    internalText: {
      get() {
        return this.text;
      },
      set(value) {
        this.$emit("update:text", value);
      },
    },
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    LabelTextArea: () => import("@/components/LabelTextArea"),
  },
};
</script>